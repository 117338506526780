<template>
  <div class="container py-5">
    <p class="fw-bold">第十六屆美沃斯大會</p>
    <p>時間：2023/12/15-17</p>
    <p>地點：廣州白雲國際會議中心</p>
    <p>展位：A60</p>

    <p>
     美沃斯國際醫學美容大會，涵蓋學術技術、經營管理與科技產品等領域，每年都能吸引各國的眾多行業精英及供應商廣泛參加，歷年參會人數近20000人，參展商數百家， 是醫美產業規模和影響力大的盛會之一。
    <br><br>
     作為美容美髮儀器行業的重要盛會，廣州美沃斯大會匯集了全球各地的行業精英和企業領袖，為與會者提供了一個展示產品、交流經驗、建立合作關係的絕佳平台。 銘伊恆美將藉助此次大會的機會，進一步拓展全球市場，與更多的合作夥伴建立策略合作關係，共同推動美容美髮儀器產業的發展。
    <br><br>
     在科技創新、品質卓越、多元化業務和國際化視野的引領下，銘伊恆美將繼續秉持「以人為本，以質取勝」的企業理念，不斷提升產品和服務的質量，為全球消費者帶來更 美好的生活體驗。
    </p>

    <img
      class="img-md rounded"
      src="@/assets/images/zh-TW/news/new_32.jpg"
    />


  </div>
</template>
<script>
export default {};
</script>
